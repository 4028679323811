import Clickable from '/components/Clickable';
import Loading from '/components/Loading';
import ProductCard from '/components/product-card/ProductCard';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getCollectionProducts } from '/services/searchspring';
import { useEffect, useState } from 'react';

const CollectionCarousel = ({ config }) => {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.innerWidth < 1024);
		};

		checkMobile();
		window.addEventListener('resize', checkMobile);

		return () => window.removeEventListener('resize', checkMobile);
	}, []);

	useEffect(() => {
		async function getProducts() {
			setLoading(true);
			const productsResponse = await getCollectionProducts({
				collectionHandle: config.collectionHandle,
				resultsPerPage: config.maxProducts || 48,
			});

			if (productsResponse && productsResponse.results) {
				setProducts(productsResponse.results);
				setLoading(false);
			}
		}

		if (config.collectionHandle) {
			getProducts();
		}
	}, [config.collectionHandle, config.maxProducts]);

	const splitProductsArray = config.maxProducts ? products?.slice(0, config.maxProducts) : products;

	const initialDisplayCount = isMobile ? 2 : 4;
	const displayedProducts = expanded
		? splitProductsArray
		: splitProductsArray.slice(0, initialDisplayCount);

	if (loading) {
		return (
			<div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-[438px] flex items-center justify-center">
				<Loading height="50px" width="50px" />
			</div>
		);
	}

	return (
		<div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
			{config.heading && (
				<Typography className="text-center mb-8" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className="relative">
				<ul className="grid grid-cols-2 gap-y-10 gap-x-6 lg:grid-cols-4 xl:gap-x-8">
					{displayedProducts.map((product, i) => (
						<ProductCard
							key={product.handle}
							product={product}
							heapLocation="Collection Carousel"
							rank={i}
						/>
					))}
				</ul>
				{splitProductsArray.length > initialDisplayCount && (
					<div className="flex justify-center mt-10">
						<Clickable onClick={() => setExpanded(!expanded)} variant="filled">
							{expanded ? 'Show Less' : 'Show More'}
						</Clickable>
					</div>
				)}
			</div>
		</div>
	);
};

CollectionCarousel.propTypes = {
	config: PropTypes.object,
};

export default CollectionCarousel;
